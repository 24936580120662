import React, { useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";

import Header from "./common/Header";
import Footer from "./common/Footer";
import Main from "./Main";
import SubPage from "../router/SubPage";
import SlickHome from "./subPage/SlickHome";

import "../sass/common/common.scss";
import Modal from "./Modal";

function App() {
  const params = useParams();
  // true: slick, false: default(기존)
  const [slickMode, setSlickMode] = useState(true);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [modalImage, setModalImage] = useState("/image/notice.svg");

  const defaultHome = () => {
    return (
      <>
        <Header></Header>
        <Switch>
          <Route exact path="/">
            <Main></Main>
          </Route>
          <Route exact path="/:pageName">
            <SubPage></SubPage>
          </Route>
        </Switch>
        <Footer />
      </>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  return (
    <div className="App">
      {/* <button
        className="notice-button attention-animation"
        onClick={() => setIsModalOpen(true)}
      >
        주주 공지 📢
      </button> */}
      {slickMode ? <SlickHome /> : defaultHome()}
      {/* <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageSrc={modalImage}
      /> */}
    </div>
  );
}

export default App;
